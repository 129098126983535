import React, { useEffect, useState } from 'react';
import './home.css';
import { Row, Col, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from './title/phoneInput';
import {
  sMainHeading0,
  sMainHeading1,
  sMainHeading2,
  sMainSubHeading,
  FooterSection1Data,
} from '../../utils/staticString';
import { IosAppDownloadLink, AndoidAppDownloadLink } from '../../constants';
import HeroCurve from '../../assets/images/curve.svg'
import Features from '../features';
import { httprequest } from '../../controller/controller';
import { SuccessModal } from '../../common/success-modal';
import WhiteArrowHero from '../../assets/images/white_arrow_hero.png'


export default function Home() {
  const [referralId, setReferralId] = useState(null);
  const [contactNumber, setContactNumber] = useState();
  const { id } = useParams();
  const [loadingState, setLoadingState] = useState(false);
  const [modalData, setModalData] = useState({});
  
  useEffect(() => {
    setReferralId(id);
  }, [id, modalData]);
  
  const submitNumber = async () => {
    setLoadingState(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (
      contactNumber &&
      contactNumber.length >= 9 &&
      contactNumber.length < 14
    ) {
      const body = {
        phone_number:
          contactNumber[0] === '0'
            ? contactNumber.slice(1, contactNumber.length)
            : contactNumber,
        referral_code: referralId,
      };
      const res = await httprequest(body);
      setLoadingState(false);
      setModalData(res);
    } else {
      setLoadingState(false);
      setModalData({success: false})  
    }
  };
  const handleContactNumberChange = (number) => {
    setContactNumber(number);
  };
  return (
    <Spin size="large" tip="Proccessing..." spinning={loadingState}>
      <Row className="container" justify="center">
        <Row className="home">
          <Col span={24}>
            <Row justify="center">
              <Col xs={23} sm={23} md={22} lg={24} xl={16} xxl={16}>
                {}
                <Row justify="center" align="middle">
                  <Col className="main-title">
                    <span className="pink">{sMainHeading0}</span>  
                    <span className="pink">{sMainHeading1()}</span>
                    <p>{sMainHeading2}</p>
                  </Col>
                </Row>
                <Row justify="center" align="middle">
                  <Col xs={22} sm={22} md={23} lg={24} xl={22} xxl={17}>
                    <p className="homeHeadingContent">{sMainSubHeading}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24} sm={24} md={22} lg={22} xl={20} xxl={20}>
                <Row justify="center">
                  <PhoneInput
                    value={contactNumber}
                    setContactNumber={handleContactNumberChange}
                    onSubmit={submitNumber}
                  />
                  {/* <img className="whiite-arrow-hero" src={WhiteArrowHero} alt="white-arrow" /> */}
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              align="middle"
              className="downloadLinks"
            >
              <Col xs={23} sm={22} md={14} lg={12} xl={10} xxl={10}>
                <Row 
                  justify="center"
                  align="middle"
                  gutter={[
                  { xs: 8, sm: 8, md: 8, lg: 32, xl: 48, xxl: 48,},
                  { xs: 74, sm: 74, md: 74, lg: 32, xl: 48, xxl: 48,},
                ]}>
                  <Col>
                    <Row justify="center">
                      <a href={AndoidAppDownloadLink}>
                        <img src={FooterSection1Data.androidIconPNG} alt="android" />
                      </a>
                    </Row>
                  </Col>
                  <Col>
                    <Row justify="center">
                      <a href={IosAppDownloadLink}>
                        <img src={FooterSection1Data.iosIconPNG} alt="android" />
                      </a>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
      <Row className="curve">
        <img src={HeroCurve} alt="curve"/>
      </Row>
      <Features />
      <SuccessModal modalData={modalData} />
    </Spin>
  )
}
