import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Success from './components/success/Success';
import NotFound from './components/notfound/NotFound';
import PrivacyPolicyPage from './components/pages/privacy-policy';
import TermsConditionPage from './components/pages/t-and-c';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route
            exact
            path="/success"
            component={Success}
          ></Route>
          <Route
            exact
            path="/privacy-policy"
            component={PrivacyPolicyPage}
          ></Route>
          <Route
            exact path="/terms"
            component={TermsConditionPage}
          ></Route>
          <Route
            exact
            path="/"
            component={Home}
          ></Route>
          <Route
            path="/:id"
            component={Home}
          ></Route>
          {/* <Route
            path="*"
            component={NotFound}
          ></Route> */}
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
