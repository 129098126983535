import React from 'react';
import { Result } from 'antd';
import './success.css';

export default function Success() {
  return (
    <div className="success">
      <Result
        status="success"
        title="Process Successfully Completed"
        subTitle=""
      />
    </div>
  );
}
