import React from 'react';
import { Col, Row, Rate } from 'antd'

import { section4 } from '../../../utils/staticString';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './styles.css'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1600 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1200, min: 992 },
    items: 2
  },
  smallTablet: {
    breakpoint: { max: 992, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 576 },
    items: 1
  }
};

const renderSingleReview = () => {
  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      // showDots={true}
      infinite={true}
      arrows={false}
      centerMode={false}
      autoPlay={true}
      // autoPlaySpeed={10}
      // keyBoardControl={true}
      // customTransition="fadein .5"
      transitionDuration={1000}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {
        section4.reviews.map((review, i) => {
          return (
            <Col
              span={24}
              key={i}
              className="review"
            >
              <Row className="review-pic">
                <img src={review.pic} alt={review.name} width="100"/>
              </Row>
              <Row justify="center" align="top">
                <p className="review-name">{review.name}</p>
              </Row>
              <Row justify="center" align="top">
                <p className="review-title">{'«' + review.title + '»'}</p>
              </Row>
              <Row>
                <p className="review-description">{review.description}</p>
              </Row>
              <Row className="review-star" justify="center">
                <Rate disabled defaultValue={review.star} />
              </Row>
            </Col>
          )
        })
      }
    </Carousel>
  );
}

function Section4() {
  return (
    <div className="section4" >
      <Row justify="center" align="top">
        <Col xs={22} sm={22} md={20} lg={16} xl={16} xxl={16}>
          <h2>{section4.title}</h2>
        </Col>
      </Row>
      <Row justify="center" align="top">
        <Col xs={22} sm={22} md={20} lg={16} xl={16} xxl={16}>
          <h4>{section4.subTitle}</h4>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
      >
        <Col xs={22} sm={20} md={20} lg={22} xl={20} xxl={20}>
          {renderSingleReview()}
        </Col>
      </Row>
    </div>
  )
}

export default Section4
