import React from 'react';
import FooterSection2 from './footer-section2/index';

export default function Footer() {
  return (
    <div>
      <FooterSection2 />
    </div>
  );
}
