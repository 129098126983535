import React from 'react';

import svgBroadBand from '../assets/images/broadBand.svg';
import svgElectricity from '../assets/images/electricity.svg';
import svgGas from '../assets/images/gas.svg';
import svgLandline from '../assets/images/landline.svg';
import svgMobile from '../assets/images/mobile.svg';
import svgStream from '../assets/images/stream.svg';
import svgWater from '../assets/images/water.svg';
import svgCouncil from '../assets/images/council.svg';
import svgPinkStroke from '../assets/images//brush_0_pink_1.png';

import getBetterDeals from '../assets/images/getBetterDealsPic.svg';
import syncBillEmails from '../assets/images/syncBillEmailsPic.svg';
import stayInTheLoop from '../assets/images/stayInTheLoopPic.svg';

import reviewPic1 from '../assets/images/reviewPic1.svg';
import reviewPic2 from '../assets/images/reviewPic2.svg';
import reviewPic3 from '../assets/images/reviewPic3.svg';
import successModalBg from '../assets/popUpImages/bg1.png';

import androidIcon from '../assets/footerLogos/androidIcon.png';
import appleIcon from '../assets/footerLogos/appleIcon.png';
import bannerIcon from '../assets/footerLogos/banner.png';
import mobileLinkTextIcon from '../assets/footerLogos/mobilelinktext.png';
import DarkBlueScribble from '../assets/images/dark_blue_scribble.png';
const sMainSubHeading =
  '100% free and trusted to automagically sync and track your bills, send payment reminders, and automatically scan the market for better deals on your services to give you total financial control.';

const sMainHeading0 = 'Meet ';
const sMainHeading1 = () => {
  return (
    <span>
      <img style={{position:'absolute', paddingTop: 24, width: '7rem'}} src={svgPinkStroke} alt="pink-stroke" /> 
      Ayla,
    </span>
  )
};
const sMainHeading2 = ' your smart life admin';
const sheaderBtnLeftfirstText = 'How it works';
const sheaderBtnLeftsecondText = 'Contact';
const sheaderGetstartedBtnText = 'Get started';
const bottomDisclaimer1 = () => 
  <p>AYLA app use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements. </p>
const bottomDisclaimer2 = () => 
  <p>By resource limitations, Ayla does not compare all brands in the markets or all products offered by all brands. Certain brands or products may not be available or provided to you if you proceed with a plan with Ayla. Ayla might receive a fee from suppliers or their partners if you signed up. When making product suggestions, Ayla is independent and works in your best interest. Ayla dose not provide any financial advice.'</p>
export const sSection1Title = 'What can Ayla do?';
export const sSection1SubTitle = 'List of all service types which can be managed with Ayla.'
export const sSection2Title = 'How it works';
export const sBills = [
  {
    image: svgElectricity,
    name: 'Electricity',
  },
  {
    image: svgGas,
    name: 'Gas',
  },
  {
    image: svgBroadBand,
    name: 'Broadband',
  },
  {
    image: svgLandline,
    name: 'Landline',
  },
  {
    image: svgMobile,
    name: 'Mobile',
  },
  {
    image: svgStream,
    name: 'Stream',
  },
  {
    image: svgWater,
    name: 'Water',
  },
  {
    image: svgCouncil,
    name: 'Council',
  },
];

export const sFunctions = [
  {
    icon: syncBillEmails,
    title: 'Sync bill emails',
    description:
      'Let Ayla bring all your bills into one easy and organised space.',
  },
  {
    icon: stayInTheLoop,
    title: 'Stay in the loop',
    description:
      'See your payment overviews and get real time reminders of upcoming bills to always pay on time.',
  },
  {
    icon: getBetterDeals,
    title: 'Get better deals',
    description:
      'Get notified about price hikes and let Ayla find and help you instantly switch to better deals.',
  },
];

export const section3 = {
  smartBill: {
    title: 'SMART BILL MANAGEMENT',
    titleColor: '#5B9594',
    subTitle: () => <span>10X better <span className="section3-control">control</span> of that boring life admin</span>,
    description:
      'Ayla’s here to do it so you never have to. Utilities, insurance, and rent – she’ll keep you on top of all your bills. The best part? It won’t cost you a cent.',
  },
  compareAndSwitch: {
    title: 'COMPARE & SWITCH',
    titleColor: '#9784F1',
    subTitle: () =><span>Avoid "lazy tax" for the lifetime</span>,
    description:
      'Pocket more cash as Ayla auto-compares your bills across the market and finds you even better deals. And if you ever want to switch, it’ll be a piece of cake – no calls, emails, or being on hold. Ever.',
  },
  smartReminders: {
    title: 'SMART REMINDERS',
    titleColor: '#FE8EDF',
    subTitle: () => <span>Say <span className="section3-goodbye">goodbye</span> to overdue bills</span>,
    description:
      "Pay on time, every time. With Ayla's reliable and automated payment reminders, you'll avoid late payments and unnecessary fees.",
  },
  rateProviders: {
    title: 'RATE PROVIDERS',
    titleColor: '#FCD364',
    subTitle: () => <span>Reclaim your opinion power</span>,
    description:
      "Rate your providers so Ayla can learn what services are worth promoting. This way, she'll only show you what you and others already love. With Ayla, you'll have the power to make providers offer more quality and fair services. ",
  },
};

export const section4 = {
  title: 'HEAR IT FROM THEM',
  subTitle: 'What our community is saying',
  reviews: [
    {
      pic: reviewPic1,
      name: 'Pctavious',
      title: 'Unmatched',
      description:
        'Absolutely exquisite app. Very easy to use and a great way for anyone to take better control of their finances and save a bit of money. Nothing else like this for the Australian market.',
      star: 5,
    },
    {
      pic: reviewPic2,
      name: 'ItalianSushi',
      title: 'Easy to use',
      description:
        'I suck at remembering to put money aside to pay my bills. Now I don’t have to. Luuuvv this idea!',
      star: 5,
    },
    {
      pic: reviewPic3,
      name: 'Fiftycentd',
      title: 'Great concept',
      description:
        'I’ve been looking for a tool for all my bills, but there is nothing on the market. This sounds like a good concept of paying weekly and keep all bills compared monthly.',
      star: 5,
    },
    {
      pic: reviewPic1,
      name: 'Alysa',
      title: 'Superbly organized',
      description:
        'After using Ayla, organizing bills is now a breeze. I just open the app, see everything in one place, easily keep track of it all and not have to worry about missing payments again.',
      star: 5,
    },
    {
      pic: reviewPic3,
      name: 'Alexandra',
      title: 'Complete transparency',
      description:
        'What I love most about Ayla is the transparency and complete control over my bills. Ayla makes it easy to stay organized and find even better deals that I would have never known about.',
      star: 5,
    },
  ],
};

export const section6 = {
  title: 'Frequently Asked Questions',
  subTitle: 'Learn more about AYLA and our service',
  qanda: [
    {
      question: 'What is AYLA?',
      answer: [
        'Ayla is your personal AI assistant to help you take back control of your life admin. Start with managing all your bills in one place, automagically.',
        "She will remind you of the due dates, alert you on price hikes, and run auto comparisons to ensure you always have great deals. You can rate your services and the feedback will impact the provider's future sales, to encourage them to take their services more seriously.",
        'And this is just the start. She will be able to do much more in the near future to make your life admin automatic and completely stress-free. You can soon look forward to Ayla being able to monitor direct debits, cancel unwanted subscriptions, and extend payment due dates. She will also be able to manage multiple properties and help you take care of the elderly.',
      ],
    },
    {
      question: 'How will AYLA help me save money on my bills?',
      answer: [
        'Australians have paid $25 billion too much on their bills every year – that\'s about $2,500 per household (AYLA research 2020). Most of these fees are due to the "loyalty tax" - the longer you are with a provider, the higher price you pay. Most people know this, but who has the time to keep searching for better deals?',
        'Ayla does.',
        "Based on your past bills, Ayla will learn exactly how you use your service to then search the market and find even better deals to save you money. Once deals are found, Ayla will run checks on the providers before sending you an Ayla approved offer. Then, it's your choice to make a seamless switch within the app. Ayla will keep doing this for you, so you can always be sure you're on the top deal.",
      ],
      secondaryAnswer: [
        '(By resource limitations, Ayla does not compare all brands in the markets or all products offered by all brands. Certain brands or products may not be available or provided to you if you proceed with a plan with Ayla. Ayla might receive a fee from suppliers or their partners if you signed up. When making product suggestions, Ayla is independent and works in your best interest.)',
      ],
    },
    {
      question: 'Is AYLA secure?',
      answer: [
        'We take security, privacy, and data seriously. AYLA is verified as a trusted partner with Microsoft and is currently in the process of being verified with Google. This way, we are only able to sync your emails with their official APIs. All the data we work with is highly secured with bank-level 256-bit encryption. AYLA relies on Australia-based local data centres for its servers and follows the Australian Privacy Act for its security practices. On top of all this, AYLA is insured against cybercrime.',
      ],
    },
    {
      question: 'Is AYLA really free?',
      answer: [
        'Definitely, AYLA is 100% free for everyone. How does AYLA make its living? Directly from the service providers themselves.',
        "When AYLA finds a better deal and you choose to take it, AYLA may only charge fees to the providers. This way, it's a win/win situation – providers save on marketing & sales, AYLA earns the fuel to run free services, and you receive a better deal and save money. This all drives the providers' focus from marketing & sales to services that have quality and add real value.",
      ],
    },
  ],
};

const FooterSection1Data = {
  title: 'TRY AYLA FREE',
  subTitle: 'Get Ayla today',
  description:
    'No fees and completely free to use. Take control of your life admin today, sign up for AYLA.',
  androidIconPNG: androidIcon,
  iosIconPNG: appleIcon,
  bannerIconPNG: bannerIcon,
};

const PrivacypolicyData = {
  title: 'INDEPENDENT & SECURE',
  subTitle1: 'Your privacy, ',
  subTitle2: () => {
    return (
      <span className="our-priority">
        <img className="dark-blue-scribble" src={DarkBlueScribble} alt="dark-blue-scribble" />
        our priority
      </span>
    )
  },
  description: 'We take your security and privacy seriously. When it comes to your data, expect nothing less than bank-grade encryption.',
};

export const privacyPolicyData = {
  heading1: 'AYLA App – Privacy Policy',
  heading2: () => (
    <p>
      Noobill Pty Ltd ABN 11 626 844 273 (<strong>we</strong>,{' '}
      <strong>us</strong> or <strong>our</strong>), understands that protecting
      your personal information is important. We provide an AI powered mobile
      application that allows for price-comparison, management and payment of
      all of your recurring services (<strong>Services</strong>). This Privacy
      Policy sets out our commitment to protecting the privacy of personal
      information provided to us, or otherwise collected by us when providing
      our Services or when otherwise interacting with you. '
    </p>
  ),
  section1title: 'The information we collect',
  section1heading: () => (
    <p>
      <strong>Personal information:</strong> is information or an opinion,
      whether true or not and whether recorded in a material form or not, about
      an individual who is identified or reasonably identifiable. The types of
      personal information we may collect about you include:
    </p>
  ),
  section1list: [
    'your name and your date of birth;',
    'your contact details, including email address, street address and/or telephone number;',
    'any personal information or details contained within your AYLA inbox;',
    'if you expressly consent to us accessing your personal email inbox, any personal information or details contained within your email inbox;',
    'if you expressly consent through our app, details about your transaction data including your bank name, account number, financial details and transaction history (Transaction Data);',
    'your current service providers and the types of accounts you hold with each service provider including average monthly spend;',
    'your credit card or other payment details (used to transact with us, or through our third party payment processor);',
    'if you sign up to a third party service provider through our platform and we are required to verify your identity, your personal identification documents such as your driver’s licence;',
    'information you provide to us, including through feedback or customer surveys;',
    'details of services we have provided to you and/or that you have enquired about, and our response to you;',
    'support requests submitted to us and our response to you;',
    'your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour;',
    'information about your access and use of our Services, including through the use of Internet cookies, your communications with our online Services, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider;',
    'additional personal information that you provide to us, directly or indirectly, through your use of our Services, associated applications, associated social media platforms and/or accounts from which you permit us to collect information (including your emails); and',
    'any other personal information requested by us and/or provided by you or a third party',
  ],
  section2heading: () => (
    <p>
      <strong>Sensitive information:</strong> is a sub-set of personal
      information that is given a higher level of protection. Sensitive
      information means information relating to your racial or ethnic origin,
      political opinions, religion, trade union or other professional
      associations or memberships, philosophical beliefs, sexual orientation or
      practices, criminal records, health information or biometric information.
    </p>
  ),
  section2title:
    'We do not actively request sensitive information about you. However, in the course of providing our Services to you, we may come across sensitive information about you, including where you grant us permission to access to your email inbox, or you provide us with your personal identification documents, in which case, we will only use it as required or authorised by law. If at any time we need to collect sensitive information about you, unless otherwise permitted by law, we will first obtain your consent and we will only use it as required or authorised by law. ',
  section3heading: 'How we collect personal information',
  section3title1:
    'We collect personal information in a variety of ways, including:',
  section3title2: [
    {
      heading: 'Directly',
      text: 'We collect personal information which you directly provide to us, including when you register for an account with us, through the ‘contact us’ form on our website or when you request our assistance via email, our online chat or over the telephone.',
    },
    {
      heading: 'Indirectly',
      text: 'We may collect personal information which you indirectly provide to us while interacting with us, such as from your personal emails (if you have consented to this), your AYLA inbox (which you must use if you are entering into any contracts with our vendors), when you use our website or mobile app, in emails, over the telephone and in your online enquiries.',
    },
    {
      heading: 'From third parties',
      text: 'We collect personal information from third parties, such as your use of our website from our analytics and cookie providers and marketing providers. See the “Cookies” section below for more detail on the use of cookies.',
    },
  ],
  section3title3:
    'your Transaction Data from your bank and other financial services providers, provided you expressly grant us consent to access such information (including through our open banking partner, Basiq); and',
  section3title4:
    'your use of our website from our analytics and cookie providers and marketing providers. See the “Cookies” section below for more detail on the use of cookies.',
  section4heading:
    'Why we collect, hold, use and disclose personal information',
  section4title1: [
    {
      heading: 'Personal information',
      text: 'We may collect, hold, use and disclose personal information for the following purposes:',
    },
  ],
  section4title2: [
    'to enable you to access and use our Services, including to provide you with a login to your account;',
    'to provide our Services to you, including to compare and switch your service provider accounts and to manage and remind you to pay your bills;',
    'to enable you to access and use our associated applications and associated social media platforms;',
    'to contact and communicate with you about our Services, including in response to any support requests you lodge with us or other enquiries you make with us;',
    'for internal record keeping, administrative, invoicing and billing purposes;',
    'for analytics, market research and business development, including to operate and improve our Services, associated applications and associated social media platforms;',
    'to run promotions, competitions and/or offer additional benefits to you;',
    'for advertising and marketing, including to send you promotional information about our products and services and other information that we consider may be of interest to you;',
    'to comply with our legal obligations and resolve any disputes that we may have; and',
    'if otherwise required or authorised by law.',
  ],
  section5heading: 'Our disclosures of personal information to third parties',
  section5title1: 'We may disclose personal information to:',
  section5title2: [
    'third party service providers for the purpose of enabling them to provide their services to us, including (without limitation) IT service providers such as Mailchimp and Intercom, data storage, web-hosting and server providers such as AWS and Mailcheap, email marketing providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;',
    'our employees, contractors and/or related entities;',
    'our existing or potential agents or business partners;',
    'sponsors or promoters of any promotions or competition we run;',
    'anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;',
    'courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;',
    'courts, tribunals, regulatory authorities and law enforcement officers, as required or authorised by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;',
    'third parties to collect and process data, such as Mixpanel and Google Analytics (to find out how Google uses data when you use third party websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL Google may use from time to time), Facebook Pixel or other relevant analytics businesses; and',
    'any other third parties as required or permitted by law, such as where we receive a subpoena.',
  ],
  section6heading: 'Overseas disclosure',
  section6title1:
    'While we store personal information in Australia, where we disclose your personal information to the third parties listed above, these third parties may also store, transfer or access personal information outside of Australia, including but not limited to, the United States, the United Kingdom, India and Pakistan.',
  section6title2:
    'Unless we seek and receive your consent to an overseas disclosure of your personal information, we will only disclose your personal information to countries with laws which protect your personal information in a way which is substantially similar to the Australian Privacy Principles and/or we will take such steps as are reasonable in the circumstances to require that overseas recipients protect your personal information in accordance with the Australian Privacy Principles.',
  section7heading:
    'Use of Google API Services (this section applies to personal information accessed through Google API Services)',

  section7title1:
    'We use Google’s Application Programming Interface (API) Service to access your email account to read your emails in order to enable you to organise your services and bills through AYLA app. ',
  section7title2: () => (
    <p>
      AYLA app use and transfer to any other app of information received from Google APIs will adhere to{' '}
      <a href="https://developers.google.com/terms/api-services-user-data-policy">
        Google API Services User Data Policy
      </a>
      , including the Limited Use requirements.
    </p>
  ),
  section7title3:
    'We limit our use of the personal information that we collect to providing or improving our Services. We do not use the personal information for any other purposes.',
  section7title4:
    'We only transfer the personal information to others if it is necessary to provide or improve our Services or as necessary to comply with applicable law or as part of a merger, acquisition or sale of assets where we notify you of this.',
  section7title5:
    'We do not use or transfer the personal information for serving ads, including retargeting, personalised or interest based advertising.',
  section7title6:
    'We don’t allow humans to read the personal information unless',
  section7title7: [
    'we have first received your consent to view specific messages, files or other data;',
    'it is necessary for security purposes (such as investigating a bug or abuse);',
    'our use is limited to internal operations and the personal information (including derivations) have been aggregated and anonymised.',
  ],
  section7title8:
    'These restrictions apply to the raw data we obtain from the restricted scopes and data that is aggregated, anonymised or derived from them.',
  section7title9:
    'We have policies and procedures in place setting out appropriate safeguards for protecting your personal information obtained with the Google APIs. We will also ensure that our employees, agents, contractors and successors comply with the Google API Services Data Policy.',
  section8heading: 'Your rights and controlling your personal information',
  section8title: [
    {
      heading: 'Your choice',
      text: 'Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not it may affect our ability to provide our Services to you and your use of our Services.',
    },
    {
      heading: 'Information from third parties',
      text: 'If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us. ',
    },
    {
      heading: 'Anonymity',
      text: 'Where practicable we will give you the option of not identifying yourself or using a pseudonym in your dealings with us. ',
    },
    {
      heading: 'Restrict and unsubscribe',
      text: 'To object to processing for direct marketing/unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.',
    },
    {
      heading: 'Access',
      text: 'You may request access to the personal information that we hold about you.  An administrative fee may be payable for the provision of such information. Please note, in some situations, we may be legally permitted to withhold access to your personal information. If we cannot provide access to your information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain about the refusal. If we can provide access to your information in another form that still meets your needs, then we will take reasonable steps to give you such access.',
    },
    {
      heading: 'Correction',
      text: 'If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information found to be inaccurate, out of date, incomplete, irrelevant or misleading. Please note, in some situations, we may be legally permitted to not correct your personal information. If we cannot correct your information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain about the refusal.',
    },
    {
      heading: 'Complaints',
      text: 'If you wish to make a complaint, please contact us using the details below and provide us with full details of the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take in response to your complaint. If you are not satisfied with our response, you also have the right to contact the Office of the Australian Information Commissioner. Office of the Australian Information Commissioner (OAIC) Website: www.oaic.gov.au, Phone: 1300 363 992, Postal Address: Office of the Australian Information Commissioner, GPO Box 5218, Sydney, NSW, 2001.',
    },
  ],
  section9heading: 'Storage and security',
  section9title1:
    'We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.',
  section9title2:
    'While we are committed to security, we cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk. ',
  section10heading: 'Cookies',
  section10title1: `We may use cookies on our website from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. If and when you choose to provide our online website or mobile app with personal information, this information may be linked to the data stored in the cookie.`,
  section10title2:
    'You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.',
  section11heading: 'Links to other websites',
  section11title:
    'Our website may contain links to other party’s websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.',
  section12heading: 'Amendments',
  section12title:
    'We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our website. We recommend you check our website regularly to ensure you are aware of our current Privacy Policy.',
  section14heading: 'For any questions and notices, please contact us at:',
  section14title1: 'Noobill Pty Ltd ABN 11 626 844 273',
  section14title2: 'Email: info@ayla.app',
  section14title3: '',
};

export const TandCData = {
  heading1: 'Ayla Terms And Conditions',
  heading2: 'Welcome',
  section1title:
    'We’re aiming to give you the power when it comes to choosing your service suppliers. We provide an AI powered app that allows for price comparison and management of all your recurring services within the one app. Before we get started, there are just a few terms and conditions we need to cover to make sure we’re all on the same page.',
  section1heading: 'Key Terms',
  section1titletext:
    'Please read these Terms carefully prior to accepting Terms. By accepting these Terms, you agree that:',
  section1list: [
    {
      li: 'we may disclose your personal information to third parties as set out in our Privacy Policy, including to any vendors you purchase products or services from on our Platform;',
    },
    {
      li: 'any Content on the Platform is intended only to provide you with general information on improving your financial health, and you must not use the Content on the basis that it is legal, financial, superannuation or tax advice. While we use reasonable endeavours to keep the Content up to date and to highlight financially beneficial options for you, if you choose to make use of our Content, you do so at your own risk;',
    },
    {
      li: 'our liability is limited to your rights under consumer laws and, to the extent permitted by consumer laws, our liability arising from or in connection with these Terms will be limited to us paying you $[50], and we exclude our liability for any consequential loss; ',
    },
    {
      li: 'subject to your consumer law rights, we exclude our liability for any defect or error in the Platform, including the Platform being unavailable; any illness, injury or death resulting from your use of the Platform; any transaction entered into between you and any Vendor; and any liability you incur as a result of any event beyond our reasonable control;',
    },
    {
      li: 'subject to your consumer law rights, you must indemnify us against any liability suffered by us arising from or in connection with your use of our Platform or any breach of these Terms or any applicable laws by you;',
    },
    {
      li: 'we may, at any time, discontinue the Platform or exclude any person from the Platform, for which we will not be responsible for any loss or damage; and',
    },
    {
      li: 'we may receive a benefit (which may include a referral fee or a commission) should you visit certain third-party websites via a link on the Platform, if you enter into a contract with a Vendor through the Platform or for featuring certain products or services on the Platform.',
    },
  ],
  section2heading: 'Terms And Conditions',
  section2title:
    'These terms and conditions (Terms) are between you, the individual accessing the platform (you or your), and noobill Pty Ltd (ABN [11626844273]) (we, us or our). We own or have the right to licence this web and mobile based platform, including all software, instructions and any update, modification or release of any part of that platform that we provide to you (Platform). Our Platform is available on both the Apple App Store and the Google Play Store, and may be available through other addresses or channels.',
  section3heading: 'Disclaimer',
  section3title:
    'Please note that our Platform and the content contained on our Platform (including any recommendations, comparisons and any other tools, information, insights or resources made available to you) (Content) are provided for general information purposes only, and on an “as is” basis. The Content is intended only to provide general information on improving your financial health, and you must not use the Content on the basis that it is legal, financial, superannuation or tax advice. Whilst we use reasonable endeavours to keep the information up to date and to highlight financially beneficial options for you, we make no representation that any information provided is accurate or up-to-date and we do not guarantee that any recommendations featured on our Platform are more suitable than options or businesses not featured on our Platform. If you choose to make use of our Content, you do so at your own risk. To the extent permitted by law, we do not assume any responsibility or liability, and you waive and release us from all responsibility or liability, arising from or connected with your use or reliance on the Content provided in our Platform. It is your sole responsibility to determine the suitability, reliability and usefulness of our Platform and our Content.',
  section4heading: 'Acceptance',
  section4title1:
    'You accept these Terms, including our Privacy Policy, by downloading or using our Platform. These Terms will commence on the date on which you accept these Terms and will continue until the date these Terms are terminated in accordance with the Termination clause below (Term).',
  section4title2:
    'If you access or download our Platform from (1) the App Store, you agree to any Usage Rules set forth in the App Store Terms of Service or (2) the Google Play Store, you agree to the Android, Google Inc. Terms and Conditions including the Google App Terms of Service.',
  section5heading: 'Signing up to our Platform',
  section5title1:
    'You must register on our Platform and create an account (Account) to access the functionality of our Platform. You must provide basic information when registering for an Account, such as your name, email address and phone number and you will be sent an SMS message to verify your phone number. You agree to ensure that any information you give to us when creating an Account, including personal information, is accurate and up-to-date.',
  section5title2:
    'You are responsible for keeping your Account details confidential and you will be liable for all activity on your Account, including any contracts you enter into with a third party through the Platform, whether or not these were authorised by you. You agree to immediately notify us of any unauthorised use of your Account. At our sole discretion, we may refuse to allow any person to register or create an Account.',
  section5title3:
    'Once you have created an Account, you will be given the option to link your email services to the Platform so that we can provide you with insights on the bills and services you have. We provide this service through our partner Google and Microsoft’s Gmail and Outlook APIs. For more information on Gmail & Outlook’s privacy practices, please review their applicable privacy policy.',
  section6heading: 'Vendors',
  section6title:
    'We may make a compare and switch feature available on the Platform which allows you to compare deals for products or services provided by third parties (Vendors). This feature may be provided by us or by third party aggregators (Aggregators). Sometimes when we provide you with recommendations or you use the compare and switch feature, the Vendors have paid us and/or our Aggregators a fee to have their products or services displayed, compared and/or promoted through our Platform, and as such, we and/or our Aggregators receive a benefit for recommending their products or services. ',
  section7heading: 'Third Party Contracts',
  section7title1:
    'Vendors may choose to offer products or services through the Platform and we may make these available or recommend these offers to you (Quote). Any Quote placed on the Platform or sent to you is an offer by the Vendor to sell a particular product or service to you for the price specified and in accordance with any of the Vendor’s terms and conditions. ',  
  section7title2:
    'If you elect to accept a Quote and sign up for a Vendor’s products or services through our Platform (Application), we will provide your Application (including your contact details) to the Vendor. The Vendor may accept or reject your Application at their sole discretion and they may also contact you if they require any further information. If the Vendor accepts your Application, they will notify you directly and provide you with their terms and conditions to accept (but you may reject the terms and conditions and withdraw your Application before agreeing to their terms and conditions). If you accept their terms and conditions, an Order is formed and results in a separate binding agreement between the Vendor and you for the supply of products and/or services in accordance with the relevant Vendor’s terms and conditions. ',
  section7title3:
    'All communications between you and the Vendor, including any bills or invoices, will be through your AYLA email address that we will have access to (as described in our Privacy Policy). If you wish to have communications delivered to your personal email address, you will need to contact the Vendor directly to update your contact details.',
  section8heading: 'Ayla Services',
  section8title1:
    "You understand and agree that we only make available the Platform to you and we only provide the following services: (1) providing the Platform to you and any services set out on the Platform, including providing analysis on your financial behaviour, offering you recommendations and providing you with an AYLA email address (which we will have access to); and (2) helping you to form contracts with Vendors for the sale of services or products.",  
  section8title2:
    "We are not party to any agreement entered into between you and a Vendor and we have no control over the conduct of Vendors or over the pricing, quality, inclusions or any aspect of the products or services a Vendor offers. We are not the seller of any product or services offered by a Vendor and we are not acting for or on behalf of you in relation to payment for a product or service. We do not accept any liability for the products or services provided by Vendors. If you choose to subscribe to parcel tracking services through the Platform, you consent to us obtaining tracking information from your AYLA email address (which we will have access to) or your personal email address (through the Outlook or Gmail API, if you have consented) in order to accurately trace the status of your parcels and display this in the Platform.",  
  section8title3:
    "If you choose to pay your bills through the Platform, you may be redirected to your bank’s online banking application. Despite anything to the contrary, to the maximum extent permitted by law, we are not liable for any interaction between you and your bank’s online banking application, including any liability for incorrect, late or missed payments.",
  section9heading: 'Prohibited conduct',
  section9title: `You must not do, or attempt to do, anything that is unlawful; anything prohibited by any laws which apply to our Platform or which apply to you or your use of our Platform; anything which we would consider inappropriate; or anything which might bring us or our Platform into disrepute, including: (1) anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual's consent) or any other legal rights; (2) using our Platform to defame, bully, harass, threaten, menace or offend any person; (3) interfering with any user using our Platform; (4) tampering with or modifying our Platform, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Platform, including using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Platform; (5) anything that might violate any local, state, national or other law or regulation or any order of a court, including privacy regulations; or (6) facilitating or assisting a third party to do any of the above acts.`,
  section10heading: 'Intellectual Property Rights',
  section10title1:
    'We grant you a non-exclusive, royalty-free, revocable, worldwide, non-sublicensable and non-transferable licence to use our Platform during the Term in accordance with these Terms. All other uses are prohibited without our prior written consent.',
  section10title2:
    'Unless otherwise indicated, we own or licence all rights, title and interest (including intellectual property rights) in our Platform and all Content (including any Content generated by the Platform as a result of your use of it). Your use of our Platform and your use of and access to any Content does not grant or transfer to you any rights, title or interest in relation to our Platform or the Content. ',
  section10title3:
    'You must not, without the prior written consent of ourselves or the owner of the Content (as applicable): (1) copy or use, in whole or in part, any Content; (2) reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or (3) breach any intellectual property rights connected with our Platform, including by: (a) altering or modifying any of the Content; (b) causing any of the Content to be framed or embedded in another website; or (c) creating derivative works from the Content.',
  section11heading: 'User Data',
  section11title:
    'You grant us a limited licence to copy, transmit, store and back-up or otherwise access the information, and other data provided by you (or where you have consented, Gmail, Outlook and Yodlee) to us or inputted by you into the Platform, stored by the Platform, generated by the Platform as a result of your use of the Platform or received through your AYLA email address or personal email address (where consent given) (User Data) during the Term and for a reasonable period after the Term to: (1) supply our services (including for back-ups and to analyse your behaviours) to you; (2) diagnose problems with the Platform; (3) develop other services, provided we de-identify the User Data; (4) enhance and otherwise modify the Platform; and (5) as reasonably required to perform our obligations under these Terms.',
  section12heading: 'Analytics ',
  section12title1:
    'We may monitor, analyse and compile statistical and performance information based on and/or related to your use of the Platform and your associated financial habits, in an aggregated and anonymised format (Analytics). You agree that we may make such Analytics publicly available, including by commercialising the Analytics, provided that it: (1) does not contain identifying information about you; and (2) is not compiled using a sample size small enough to make the underlying personal information you provide identifiable.',
  section12title2:
    'We, and our licensors own all right, title and interest in and to the Analytics and all related software, technology, documentation and content used or provided in connection with the Analytics, including all intellectual property rights in the foregoing.',
  section13heading: 'Feedback',
  section13title:
    'You agree that: (1) all intellectual property rights in any idea, suggestion, recommendation or request by you, whether made verbally, in writing, directly or indirectly, in connection with the Platform (Feedback), will at all times vest, or remain vested, in us; and (2) we may use Feedback in any manner which we see fit (including to develop new features) and no benefit will be due to you as a result of any use by us of any Feedback.',
  section14heading: 'Consumer Guarantees',
  section14title1:
    'Certain legislation including the Australian Consumer Law in the Competition and Consumer Act 2010 (Cth), and similar consumer protection laws and regulations may confer you with rights, warranties, guarantees and remedies relating to our provision of our Platform which cannot be excluded, restricted or modified (Consumer Law Rights).',
  section14title2:
    'Nothing in these Terms attempts to modify or exclude your Consumer Law Rights. Any and all other warranties or conditions which are not guaranteed by Consumer Law Rights are expressly excluded where permitted, except to the extent such warranties and conditions are fully expressed in these Terms.',
  section15heading: 'Notice regarding Apple',
  section15title1:
    'To the extent that you are using or accessing our Platform on an iOS device, you further acknowledge and agree to the terms of this clause. You acknowledge that these Terms are between you and us only, not with Apple Inc. (Apple), and Apple is not responsible for the Platform and any content available on the Platform.',
  section15title2:
    'Apple has no obligation to furnish you with any maintenance and support services with respect to our Platform. ',
  section15title3:
    'If our mobile application fails to conform to any applicable warranty, you may notify Apple and Apple will refund the purchase price of the mobile application to you. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the mobile application and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be our responsibility.',
  section15title4:
    'Apple is not responsible for addressing any claims by you or any third party relating to our mobile application or your use of our mobile application, including but not limited to: (1) product liability claims; (2) any claim that our mobile application fails to conform to any applicable legal or regulatory requirement; and (3) claims arising under consumer protection or similar legislation.',
  section15title5:
    'Apple is not responsible for the investigation, defence, settlement and discharge of any third-party claim that our mobile application infringes that third party’s intellectual property rights. ',
  section15title6:
    'You agree to comply with any applicable third-party terms when using our mobile application. ',
  section15title7:
    'Apple and Apple subsidiaries are third-party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary of these Terms.',
  section15title8:
    'You hereby represent and warrant that: (1) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties.',
  section16heading: 'Liability ',
  section16title1:
    'In these Terms, Liability means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent and whether involving a third party or a party to these Terms or otherwise.',
  section16title2:
    'Despite anything to the contrary, to the maximum extent permitted by law, we will not be liable for, and you waive and release us from and against, any Liability caused or contributed to by, arising from or connected with: (1) your acts or omissions; (2) any use or application of the Platform by a person other than you, or other than as reasonably contemplated by these Terms; (3) any interaction between you and any Vendor or third party listed on the Platform; (4) the absence of, or reduction in, any anticipated result, outcome or benefit with respect to the Platform; (5) any illness, injury or death resulting from use of the Platform; and/or (6) any event outside of our reasonable control, except to the extent any such Liability was directly caused by our negligent act or negligent omission.',
  section16title3:
    'This clause will survive the termination or expiry of these Terms.',
  section17heading: 'Limitations on Liability ',
  section17title1: `Despite anything to the contrary, to the maximum extent permitted by law and whether under statute, contract, equity, tort (including negligence), indemnity or otherwise: (1) our maximum aggregate Liability arising from or in connection with these Terms will be limited to us paying you [$50]; and (2) we will not be liable to you for any loss of profit (including anticipated profit), loss of benefit (including anticipated benefit), loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings (including anticipated savings), loss of reputation, loss of use and/or loss or corruption of data.`,
  section17title2:
    'This clause will survive the termination or expiry of these Terms.',
  section18heading: 'Third Party Inputs',
  section18title1:
    'You agree that the provision of the Platform may be contingent on, or impacted by, third parties or any goods and services provided by third parties, including information provided to us by Vendors, Basiq and third parties that provide us with IT services, end users, merchants, suppliers, or other subcontractors (Third Party Inputs). Despite anything to the contrary, to the maximum extent permitted by law, we will not be responsible and will have no Liability for any default or breach of these Terms or any law if such default or breach was caused or contributed to by any Third Party Inputs.',
  section18title2:
    'To the extent that any Third Party Inputs are required by us in order to be able to provide the Platform to you: (1) we agree to, where applicable, obtain and maintain the required licences for access and use of any Third-Party Inputs (at no additional cost to you); and (2) you agree to: (a) where these are notified to you, adhere to the terms and conditions of any such Third-Party Input licences; and (b) comply with our reasonable instructions and directions and the directions of the third-party provider.',
  section18title3:
    "The compare and switch feature on the Platform powered by Aggregators such as CIMET Sales Pty Ltd (ABN 72 620 395 726). You acknowledge and agree that our Aggregators do not compare all available products or offerings in the market, and that at time, certain products or offerings may not be available or offered to you. By using the Platform, you agree to any terms and conditions set by our Aggregators (CIMET: <a href=\"https://www.cimet.com.au/terms-and-conditions\" target=\"_blank\">T&C</a>, <a href=\"https://www.cimet.com.au/privacy-policy\" target=\"_blank\">Privacy Policy</a>). Noobill and Aggregators may receive a referral fee when you subscribe to a Vendor's product or offering.",
  section18title4:
    "You acknowledge and agree that your receipt of the benefits provided by the Third Party Input's or any services provided by the Aggregators is subject to your compliance with this clause.",
  section19heading: 'Warranties and Indemnities',
  section19title1:
    'You represent, warrant and agree that: (1) you have full legal capacity, right, authority and power to enter into these Terms and to perform your obligations under these Terms; (2) these Terms constitute a legal, valid and binding agreement, enforceable in accordance with its terms; and (3) to provide all assistance, information and documentation necessary to enable us to provide the Platform and that all information you provide to us in connection with the Platform will be true, accurate and complete. ',
  section19title2:
    'You acknowledge and agree that: (1) you use the Platform at your own risk; and (2) we do not guarantee that the Platform will be secure, timely, uninterrupted or error or virus free or that it will meet your expectations or requirements.',
  section19title3:
    'You agree that, to the maximum extent permitted by law, these Terms exclude all terms, conditions and warranties implied by statute, in fact or on any other basis, except to the extent such terms, conditions and warranties are fully expressed in these Terms.',
  section19title4:
    'Indemnity: To the maximum extent permitted by law, you must indemnify us, and hold us harmless, against any Liability suffered by us arising from or in connection with your use of our Platform or any breach of these Terms or any applicable laws by you, except to the extent such Liability was directly caused by our negligent act or negligent omission. This indemnity is a continuing obligation, independent from the other obligations under these Terms, and continues after these Terms end. You agree that it is not necessary for us to suffer or incur any Liability before enforcing a right of indemnity under these Terms.',
  section19title5:
    'This clause will survive the termination or expiry of these Terms.',
  section20heading: 'Termination',
  section20title1:
    'You may terminate these Terms at any time via the “delete your account” (or similar) feature in your Account. ',
  section20title2:
    'At our sole discretion, we may suspend your Account or terminate these Terms immediately with notice to you if you are in material breach of these Terms, any applicable laws, regulations or third-party rights or for any other reason.',
  section20title3:
    'If we suspend your Account or these Terms are terminated, we will immediately cease providing the Platform and our services to you, and you will not be able to access your Account.',
  section20title4:
    'On termination of these Terms (including if your Account is deleted by you), we will remove and/or decommission your Account and if you redownload the Platform again in the future, you may not be able to see or restore any of your previous User Data, including any information related to your financial habits or spending decisions.',
  section21heading: 'General',
  section21titleContents: [
    {
      heading: 'Assignment',
      text: 'You may not assign, transfer or delegate your rights and obligations under the Terms without our prior written consent. We may without restriction assign, transfer or delegate our rights and obligations under these Terms with 30 days prior notice.',
    },
    {
      heading: 'Calculators and Tools',
      text: 'All tools and calculators on our Platform should be treated as a directional guide only and should not be relied upon as a precise indication of any result generated. All results are subject to the information you input, the relevant assumptions we have made and the accuracy of any information we have at that point in time.',
    },
    {
      heading: 'Changes to the Platform and Terms',
      text: 'You agree that we may amend these Terms at any time. If we amend these Terms in a way that will have a material adverse effect on you, we will provide you with written notice. By continuing to use the Platform, you agree to the amended terms. If you do not agree to the amendment, you must terminate these Terms in accordance with the Termination clause. ',
    },
    {
      heading: 'Competitors',
      text: 'You are prohibited from using our Platform, including our Content, in any way that competes with our business. If you breach this term, we will hold you responsible for Liability we suffer, and hold you accountable for any profits that you may make from non-permitted use. ',
    },
    {
      heading: 'Discontinuance',
      text: 'We may, with written notice to you, discontinue our Platform, in whole or in part. We may also exclude any person from using our Platform, at any time at our sole discretion. We are not responsible for any loss, damage or expense, howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent you may suffer arising from or in connection with any such discontinuance or exclusion.',
    },
    {
      heading: 'Disputes',
      text: 'In the event of any dispute arising from, or in connection with, these Terms (Dispute), the party claiming there is a Dispute must give written notice to the other Party setting out the details of the Dispute and proposing a resolution. Within 7 days after receiving the notice, the parties must meet at least once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other means, in good faith. All aspects of every such conference, except the fact of the occurrence of the conference, will be privileged. If the parties do not resolve the Dispute or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred by either Party (by notice in writing to the other Party) to litigation. Nothing in this clause will operate to prevent a Party from seeking urgent injunctive or equitable relief from a court of an appropriate jurisdiction. This clause will survive the termination or expiry of these Terms.',
    },
    {
      heading: 'Jurisdiction',
      text: 'Your use of our Platform and these Terms are governed by the laws of New South Wales and you irrevocably and unconditionally submit to the exclusive jurisdiction of the courts operating in New South Wales and any courts entitled to hear appeals from those courts and waive any right to object to proceedings being brought in those courts.',
    },
    {
      heading: 'Promotions',
      text: 'We may from time to time issue promotional codes for the Platform, including for referring a friend. The conditions of use relating to promotional codes will be specified on the Platform at the time they are issued.',
    },
    {
      heading: 'Reviews',
      text: 'You may review your experience with a Vendor on the Platform (Review). Reviews can be viewed by any user and will remain viewable [until your Account is removed or terminated]. You may only review a Vendor if you have engaged the Vendor through the Platform within the previous 12 months from the time of writing your Review. You agree to provide true, fair and accurate information in your Review. If we consider that a Review is untrue, unfair, inaccurate, offensive or inappropriate, we may delete the Review or ban you from posting Reviews. You agree not to write a Review about your own business, or of somebody else’s experience with a Vendor, including that of a friend or family member. We do not undertake to review each Review. To the maximum extent permitted by law, we are not responsible for the content of any Reviews.',
    },
    {
      heading: 'Severance',
      text: 'The provisions of these Terms are severable and if any provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision is severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.',
    },
    {
      heading: 'Third party information',
      text: 'Our Platform may contain information provided by, or links to websites operated by, third parties. Unless expressly stated otherwise, we do not control, endorse or approve and are not responsible for the content on those websites. You should make your own investigations with respect to the suitability of those websites.  ',
    },
  ],
  section22heading: 'For any questions and notices, please contact us at:',
  section22title1: 'noobill Pty Ltd (ABN [11626844273)',
  section22title2: 'Address: [121 King Street, Melbourne, VIC3000]',
  section22title3: ' Email: [info@Ayla.app]',
  // sectionheading: '',
  // sectiontitle: '',
};

export const successModalData = {
  bgImg: successModalBg,
  headingText: 'SUCCESS',
  subText: 'We have sent you a link to your mobile. Please follow the link to download the AYLA app. Enjoy!',
};

export const failureModalData = {
 headingText: 'FAILURE',
 subText: 'Kindly enter a valid phone number',
}

// Constant Export Section Should be at the end of the page

export {
  sMainHeading0,
  sMainHeading1,
  sMainHeading2,
  sMainSubHeading,
  sheaderBtnLeftfirstText,
  sheaderBtnLeftsecondText,
  sheaderGetstartedBtnText,
  FooterSection1Data,
  PrivacypolicyData,
  bottomDisclaimer1,
  bottomDisclaimer2,
};
