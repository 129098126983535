import React from 'react';
import { Row, Col } from 'antd';
import './style.css';
import { PrivacypolicyData } from '../../../utils/staticString';
import { Link } from 'react-router-dom';
import WhiteArrowMark from '../../../assets/images/white_arrow.png'

export default function PrivacyPolicySection() {
  return (
    <Row
      className="sectionBg"
      align="middle"
      justify="center"
    >
      <Col  
        xs={20}
        sm={20}
        md={20}
        lg={18}
        xl={16}
        xxl={14}
      >
        <Row className="title-privacy">
          {PrivacypolicyData.title}
        </Row>
        <Row className="subTitle-privacy">
          {PrivacypolicyData.subTitle1}
          {PrivacypolicyData.subTitle2()}
        </Row>
        <Row className="description-privacy">
          <Col
            xs={20}
            sm={20}
            md={20}
            lg={16}
            xl={14}
            xxl={12}
          >
            {PrivacypolicyData.description}
          </Col>
        </Row>
        <Row className="vheadingalign" justify="start">
          <Col>
            <button className="learnBtn">
              <Link to="/privacy-policy">Learn more</Link>
            </button>
            <img src={WhiteArrowMark} alt="white-arrow" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
