import React from 'react'
import {
  Col,
  Row,
} from 'antd'
import { section3 } from '../../../utils/staticString'
import smartBillManagementPic  from '../../../assets/images/smartBillManagementPic.png'
import compareAndSwitchPic  from '../../../assets/images/compareAndSwitchPic.png'
import smartReminderPic  from '../../../assets/images/smartRemindersPic.png'
import rateProviderPic from '../../../assets/images/rateProvidersPic.png'
import './styles.css'

const renderSection3 = (name, image, content, imagePosition="left") => {


  const renderWebContentPart = () => {
    return (
      <Col xs={0} sm={0} md={0} lg={12} xl={10} xxl={8} className="content">
        <Row align="middle" className="section3-title" style={{color: content.titleColor ? content.titleColor : '#FE8EDF'}}>
          {content.title}
        </Row>
        <Row align="middle" className="section3-subTitle">
          {content.subTitle()}
        </Row>
        <Row align="middle" className="section3-description">
          {content.description}
        </Row>
      </Col>
    );
  }


  const renderWebImagePart = () => {
    return (
      <Col xs={0} sm={0} md={0} lg={10} xl={10} xxl={8} className="section3-image" >
        <Row justify="center" align="middle" >
          <img src={image} alt={name} />
        </Row>
      </Col>
    );
  }

  return (
      <Row 
        justify="center"
        align="middle" 
        // gutter={[
        //   { xs: 8, sm: 8, md: 8, lg: 32, xl: 32, xxl: 32,},
        //   { xs: 74, sm: 74, md: 74, lg: 32, xl: 32, xxl: 32,}, 
        // ]}
        className="section3-individual"
      >
        <Col xs={20} sm={20} md={16} lg={20} xl={20} xxl={16} >
          <Row
            justify="space-around"
            align="middle"
            gutter={[
              { xs: 8, sm: 8, md: 8, lg: 32, xl: 32, xxl: 32,},
              { xs: 0, sm: 0, md: 0, lg: 32, xl: 32, xxl: 32,}, 
            ]}
          >
            <Col xs={16} sm={16} md={16} lg={0} xl={0} xxl={0} className="section3-image">
              <Row justify="center" align="bottom">
                <img src={image} alt={name} />
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} className="content">
              <Row align="top" className="section3-title" style={{color: content.titleColor ? content.titleColor : '#FE8EDF'}}>
                {content.title}
              </Row>
              <Row align="top" className="section3-subTitle">
                {content.subTitle()}
              </Row>
              <Row align="top" className="section3-description">
                {content.description}
              </Row>
            </Col>
            { imagePosition === 'right' ? renderWebContentPart() : renderWebImagePart() }
            { imagePosition === 'right' ? renderWebImagePart() : renderWebContentPart() }
          </Row>
        </Col>
      </Row>
  )
}


function Section3() {
  return (
    <div className="section3">
      {renderSection3('smart bill management', smartBillManagementPic,  section3.smartBill, 'right')}
      {renderSection3('compare and switch', compareAndSwitchPic, section3.compareAndSwitch, 'left')}
      {renderSection3('smart reminder', smartReminderPic, section3.smartReminders, 'right')}
      {renderSection3('compare and switch', rateProviderPic, section3.rateProviders, 'left')}
      
    </div>
  )
}

export default Section3
