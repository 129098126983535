import React, {useEffect, useState} from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { useLocation } from 'react-router-dom'
import './header.css';
import DarkBurgerMenu from '../../assets/images/burgerMenuDark.svg';
import LightBurgerMenu from '../../assets/images/burgerMenuLight.svg';
import LightLogo from '../../assets/images/aylaLogoLight.svg';
import DarkLogo from '../../assets/images/aylaLogoDark.svg';
import { redirectToHelpCenter } from '../../controller/controller';
import { Link } from 'react-router-dom';
import AustraliaLogo from '../../assets/logo-with_Australia.png';
import AustraliaLogoLight from '../../assets/australia-light.png'
import bannerLogo from '../../assets/Group _banner.svg'
import { IosAppDownloadLink, AndoidAppDownloadLink } from '../../constants';
import { FooterSection1Data} from '../../utils/staticString'
import androidBanner from '../../assets/android_app.png';
import iosBanner from '../../assets/ios_app.png';
export default function Header(props) {
  const location = useLocation();
  const [theme, setTheme] = useState('light');
  const [showMenu, setShowMenu] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const scrollEvent = (e) => {
    if (location.pathname === '/') {
      if (window.scrollY > 900) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent)
  }, [])

  return (
    <Row justify="space-around" align="middle" className={`topBar ${theme}`}>
      <Col xs={23} sm={22} md={24} lg={24} xl={16} xxl={16}>
        <Row justify="space-around" align="middle ">
          <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}>
            <Row justify="start" align="middle">
              <Col xs={0} sm={0} md={14} lg={14} xl={14} xxl={14}>
                <Link to="/">
                  <img src={theme === 'light' ? LightLogo: DarkLogo} alt="logoImage" className="logoImage" />
                </Link>
              </Col> 
              <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}>
                  <img src={theme === 'light' ?  AustraliaLogoLight : AustraliaLogo} alt="#1BillAustralia" onClick={() => setModalVisible(true)} className="" style={{maxWidth:'150px',cursor:'pointer'}} />
              </Col>
            </Row>
            
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
            <Row justify="start" align="middle">
              <Col xs={6} sm={6} md={0} lg={0} xl={0} xxl={0} style={{padding: '1rem'}}>
                <img src={theme === 'light' ? LightLogo: DarkLogo} alt="ayla-logo" className="logoImage" />
              </Col>
              <Col xs={12} sm={12} md={0} lg={0} xl={0} xxl={0} style={{padding: '1rem'}}>
                <img src={theme === 'light' ? AustraliaLogoLight:AustraliaLogo} alt="logoImage" onClick={() => setModalVisible(true)}  className="" style={{maxWidth:'150px',cursor:'pointer'}} />
              </Col>
              <Col xs={6} sm={6} md={0} lg={0} xl={0} xxl={0} style={{padding: '1rem'}}>
                <Row justify="end" align="middle" onClick={() => setShowMenu(!showMenu)}>
                  <img src={theme === 'light' ? LightBurgerMenu: DarkBurgerMenu} alt="burger-menu" className="logoImage" />
                </Row>
              </Col>
            </Row>
          </Col>
          {

            showMenu && (
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} >
                <Row justify="center" align="middle" className="headerButtons">
                  <h2 className={`${theme}`} onClick={redirectToHelpCenter} >
                    How it works
                  </h2>
                </Row>
              </Col>
            )
          }
          {
            showMenu && (
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} >
                <Row justify="center" align="middle" className="headerButtons">
                  <h2 className={`${theme}`} onClick={redirectToHelpCenter} >
                    Contact
                  </h2>
                </Row>
              </Col>
            )
          }

          <Col xs={0} sm={0} md={16} lg={16} xl={16} xxl={16} >
            <Row justify="end" align="middle" className="headerButtons">
              <h2 className={`${theme}`} onClick={redirectToHelpCenter} >
                How it works
              </h2>
              <h2 className={`${theme}`} onClick={redirectToHelpCenter} >
                Contact
              </h2>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        centered
        visible={modalVisible}
        style={{ top: 100 }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        >
          <Row justify="center" align="middle" style={{paddingTop:'5%'}}>
            <img src={bannerLogo} alt="AylaApp" width={150 }/>
          </Row>

        
          <Row  style={{ paddingBottom:'6%'}}
            justify="center"
            align="middle"
            className="downloadLinks"
            >
              <Row justify="center" style={{ paddingBottom:'1%'}}>
                <h2 style={{color:'#1C0745',fontSize:'28px', fontWeight:'500', fontFamily:'GT Walsheim',paddingBottom:'3%'}}> Australia's #1 Bill Tracker</h2>
                <div style={{backgroundColor: '#8063EF',display:'block', width:'255px', height:'44px', fontSize:'28px',  fontFamily:'GT Walsheim', fontWeight:700, lineHeight:'32px', color:'white', borderRadius:'10px', textAlign:'center',paddingTop:'1%', alignItems:'center'}}> Install Now</div>
              </Row>
              <Col xs={23} sm={22} md={22} lg={20} xl={22} xxl={22}>
                <Row 
                  justify="center"
                  align="middle"  
                  >
                  <Col xs={10} sm={7} md={7} lg={7} xl={7} xxl={7} >
                    <Row justify="center">
                      <a href={AndoidAppDownloadLink}>
                        <img src={androidBanner} alt="android" width="122px"/>
                      </a>
                    </Row>
                  </Col>
                  <Col xs={10} sm={7} md={7} lg={7} xl={7} xxl={7}>
                    <Row justify="center">
                      <a href={IosAppDownloadLink}>
                        <img src={iosBanner} alt="android"  width="122px" />
                      </a>
                    </Row>
                  </Col>
                </Row>
              </Col>
          </Row>        
        </Modal>
    </Row>
  );
}
