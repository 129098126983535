


import React from 'react'
import {
  Col,
  Row,
} from 'antd'
import {
  sFunctions,
  sSection2Title,
} from '../../../utils/staticString';
import pinkLine from '../../../assets/images/pinkLine.svg'
import blueCross from '../../../assets/images/blueCross.svg'
import './styles.css'

function Section2() {
  return (
    <div className="section2">
      <Row justify="center">
        <Col   
          xs={20}
          sm={20}
          md={20}
          lg={18}
          xl={16}
          xxl={16}
          className="functions"
        >
          <Row wrap={false} justify="center" align="top" gutter={[0, 0]}>
            <Col className="section2-title">
              <Row gutter={[0, 0]} align="bottom">
                <Col>
                  <img className="blueCross" src={blueCross} alt="blue-cross" />
                </Col>
              </Row>
              <Row justify="center" gutter={[0, 0]}>
                <Col>
                  <div className="text"> {sSection2Title} </div>
                </Col>
              </Row>
              <Row justify="center" gutter={[0, 0]}  align="top">
                <Col>
                  <img className="pinkLine" src={pinkLine} alt="pink-line" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={[
              { xs: 4, sm: 8, md: 10, lg: 10, xl: 12, xxl: 12,},
              { xs: 96, sm: 84, md: 72, lg: 10, xl: 12, xxl: 12,}, 
            ]}
            justify="center"
            align="top"
          >  
            {sFunctions.map((functions, i) => (
              <Col
                key='i'
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                xxl={8}
                className="functionBox"
              >
                <Row
                  gutter={[
                    { xs: 4, sm: 8, md: 10, lg: 12, xl: 16, xxl: 16,},
                    { xs: 4, sm: 8, md: 10, lg: 12, xl: 16, xxl: 16,}, 
                  ]}
                  justify="center"
                  align="top"
                >
                  <Col
                    className="functionImage"
                  >
                    <img src={functions.icon} alt={functions.icon} />
                  </Col>
                  <Col 
                      xs={24}
                      sm={24}
                      md={24}
                      lg={18}
                      xl={18}
                      xxl={18}  
                    className="functionTitle"
                  >
                    {functions.title}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={18}
                    xl={18}
                    xxl={18}                     
                    className="functionDescription">
                    {functions.description}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>          
        </Col>
      </Row>
    </div>
  )
}

export default Section2
