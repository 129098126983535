import React from 'react';
import './styles.css';
import { Row, Col } from 'antd';
import { FooterSection1Data } from '../../../utils/staticString';
import { IosAppDownloadLink, AndoidAppDownloadLink } from '../../../constants';

export default function FooterSection1() {
  return (
    <div className="section">
      <div className="section5Content">
        <Row justify="space-around" gutter={[8, 40]}>
          <Col
            xs={20}
            sm={20}
            md={20}
            lg={18}
            xl={16}
            xxl={16}
          >
            <Row gutter={[
              { xs: 8, sm: 8, md: 8, lg: 32, xl: 48, xxl: 48,},
              { xs: 74, sm: 74, md: 74, lg: 32, xl: 48, xxl: 48,}, 
            ]}>
              <Col xs={20} sm={18} md={16} lg={0} xl={0} xxl={0}>
                <Row justify="center">
                  <img
                    src={FooterSection1Data.bannerIconPNG}
                    className="bannerImage"
                    alt="banner"
                  />
                </Row>
              </Col>
              <Col xs={20} sm={20} md={16} lg={12} xl={12} xxl={12}>
                <Row className="mobile-app-title">
                  {FooterSection1Data.title}
                </Row>
                <Row className="mobile-app-subTitle">
                  {FooterSection1Data.subTitle}
                </Row>
                <Row className="mobile-app-description">
                  <Row>{FooterSection1Data.description}</Row>
                </Row>
                <Row className="vbtnalignment">
                  <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
                    <a href={AndoidAppDownloadLink}>
                      <img
                        src={FooterSection1Data.androidIconPNG}
                        className="linkIcons"
                        alt="android"
                      />
                    </a>
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
                    <a href={IosAppDownloadLink}>
                      <img
                        src={FooterSection1Data.iosIconPNG}
                        className="linkIcons"
                        alt="ios"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                <Row justify="center">
                  <img
                    src={FooterSection1Data.bannerIconPNG}
                    className="bannerImage"
                    alt="banner"
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}