import React from 'react';
import { Row, Col } from 'antd';
import './footer.css';
import logo from '../../../assets/Brandmark.png';
import fbLogo from '../../../assets/footerLogos/fbLogo.png';
import telegramLogo from '../../../assets/footerLogos/telegramLogo.png';
import twitterLogo from '../../../assets/footerLogos/twitterLogo.png'
import instagramLogo from '../../../assets/footerLogos/instagramLogo.png'
import linkedinLogo from '../../../assets/footerLogos/linkedinLogo.png'
import { redirectToHelpCenter } from '../../../controller/controller';
import { Link } from 'react-router-dom';
import { facebookLink, twitterLink, linkedinLink, instagramLink} from '../../../constants';
import { bottomDisclaimer1, bottomDisclaimer2 } from '../../../utils/staticString';

export default function FooterSection2() {
  return (
    <div className="footer">
      <div className="mainFooter">
        <Row justify="space-around" gutter={[8, 40]}>
          <Col span={24}>
            <Row justify="start" gutter={[8, 40]}>
              <Col xs={24} sm={24} md={6} lg={5} xl={6} xxl={6}>
                <Row>
                  <Link to="/">
                    <img src={logo} alt="ayla" />
                  </Link>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={10} lg={7} xl={6} xxl={6}>
                <Row onClick={redirectToHelpCenter}>
                  <h3> HOW ALYA WORKS </h3>
                </Row>
                <Row>
                  <Link to="/privacy-policy">
                    <h3> PRIVACY POLICY </h3>
                  </Link>
                </Row>
                <Row>
                  <Link to="/terms">
                    <h3>TERMS & CONDITIONS </h3>
                  </Link>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={8} lg={5} xl={6} xxl={6}>
                <Row onClick={redirectToHelpCenter}>
                  <h3> CONTACT US </h3>
                </Row>
                <Row onClick={redirectToHelpCenter}>
                  <h3> HELP & SUPPORT </h3>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={10} lg={6} xl={6} xxl={6}>
                <Row
                  gutter={[
                    { xs: 8, sm: 8, md: 8, lg: 16, xl: 16, xxl: 16 },
                    { xs: 0, sm: 0, md: 0, lg: 16, xl: 16, xxl: 16 },
                  ]}
                >
                  <Col>
                    <a href={facebookLink}>
                      <img src={fbLogo} alt="facebook" />
                    </a>
                  </Col>
                  {/* <Col>
                    <img src={telegramLogo} alt="telegram" />
                  </Col> */}
                  <Col>
                    <a href={twitterLink}>
                      <img src={twitterLogo} alt="twiiter" />
                    </a>
                  </Col>
                  <Col>
                    <a href={instagramLink}>
                      <img src={instagramLogo} alt="instagram" />
                    </a>
                  </Col>
                  <Col>
                    <a href={linkedinLink}>
                      <img src={linkedinLogo} alt="linkedin" />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row 
             gutter={[
              { xs: 0, sm: 0, md: 0, lg: 32, xl: 32, xxl: 32,},
              { xs: 0, sm: 0, md: 0, lg: 16, xl: 16, xxl: 16,}, 
             ]} 
            justify="start"
            className="footerlastrow"
          >
              <Col xs={24} sm={24} md={6} lg={5} xl={6} xxl={6}>
                <Row>
                  <p>© AYLA 2021</p>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={17} lg={18} xl={18} xxl={18}>
                <Row>
                  <p>{bottomDisclaimer1()} </p>
                  <p>{bottomDisclaimer2()} </p>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
