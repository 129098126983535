import React from 'react'
import {Col, Row, Collapse} from 'antd';
import {section6} from '../../../utils/staticString';
import './styles.css'


function Section6() {
  return (
    <Row justify="center" align="middle" className="section6">
      <Col
       xs={20}
       sm={20}
       md={22}
       lg={22}
       xl={16}
       xxl={14}
      >
        <Row justify="center" align="top">
          <Col xs={22} sm={22} md={20} lg={16} xl={16} xxl={16}>
            <h4>{section6.title}</h4>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col xs={22} sm={22} md={20} lg={16} xl={16} xxl={16}>
            <h5>{section6.subTitle}</h5>
          </Col>
        </Row>
        <Row justify="center" align="middle" className="faqList">
          <Col 
            xs={22}
            sm={22}
            md={20}
            lg={20}
            xl={22}
            xxl={20}
          >
            <Collapse 
              bordered={false}
              accordion={true}
              expandIconPosition="right"
              ghost={true}
              defaultActiveKey={[0]}
            >
              {
                section6.qanda.map((set, i) => {
                  return (
                    <Collapse.Panel className="question" header={`${i+1}. ${set.question}`} key={i}>
                      <Row className="answer">
                        <Col span={22}>
                          {
                            set.answer 
                            && set.answer.length > 0 
                            && set.answer.map((ans, i) => {
                              return (
                                <Row key={i} className="eachAnswer">
                                  <Col span={24}>
                                    {ans}
                                  </Col>
                                </Row>
                              ) 
                            })
                          }
                          {
                            set.secondaryAnswer
                            && set.secondaryAnswer.length > 0
                            && set.secondaryAnswer.map((ans, i) => {
                              return (
                                <Row key={i} className="eachSecondaryAnswer">
                                  <Col span={24}>
                                    <p>{ans}</p>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  )
                })
              }
            </Collapse>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Section6;
