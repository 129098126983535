import React from 'react';
import { Row, Col } from 'antd';
import { TandCData } from '../../../utils/staticString';
import './style.css';

export default function TermsConditionPage() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <div className="tcSection">
      <Row justify="center" gutter={[8, 40]}>
        <Col xs={22} sm={22} md={22} lg={20} xl={20} xxl={20}>
          <Row>
            <h2 className="headingtext">{TandCData.heading1}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.heading2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section1title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section1heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section1titletext}</h2>
          </Row>
          <Row className="listItems" gutter={[8, 40]}>
            <Col xs={23} sm={23} md={23} lg={22} xl={21} xxl={21}>
              <ul>
                {TandCData.section1list.map((ele, index) => (
                  <li className="titletext itemlists">{ele.li}</li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section2heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section2title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section3heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section3title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section4heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section4title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section4title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section5heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section5title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section5title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section5title3}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section6heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section6title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section7heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section7title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section7title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section7title3}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section8heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section8title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section8title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section8title3}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section9heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section9title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section10heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section10title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section10title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section10title3}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section11heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section11title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section12heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section12title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section12title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section13heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section13title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section14heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section14title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section14title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section15heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title3}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title4}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title5}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title6}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title7}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section15title8}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section16heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section16title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section16title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section16title3}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section17heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section17title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section17title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section18heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section18title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section18title2}</h2>
          </Row>
          <Row>
            <div className="titletext">
              The compare and switch feature on the Platform powered by Aggregators such as CIMET Sales Pty Ltd (ABN 72 620 395 726). 
              You acknowledge and agree that our Aggregators do not compare all available products or offerings in the market, and that at time, 
              certain products or offerings may not be available or offered to you. By using the Platform, you agree to any terms and conditions set by our Aggregators 
              (CIMET: <a href="https://www.cimet.com.au/terms-and-conditions" target="_blank">T&C</a>,&nbsp;
              <a href="https://www.cimet.com.au/privacy-policy" target="_blank">Privacy Policy</a>). 
              Noobill and Aggregators may receive a referral fee when you subscribe to a Vendor's product or offering.
            </div>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section18title4}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section19heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section19title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section19title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section19title3}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section19title4}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section19title5}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section20heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section20title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section20title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section20title3}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section20title4}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section21heading}</h2>
          </Row>
          <Row gutter={[8, 40]}>
            <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
              {TandCData.section21titleContents.map((ele, index) => (
                <Row>
                  <Col span={5}>
                    <h2 className="headertexts">{ele.heading} :</h2>
                  </Col>
                  <Col span={19}>
                    <h2 className="titletext aligntext">{ele.text}</h2>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row>
            <h2 className="headertexts">{TandCData.section22heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section22title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section22title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{TandCData.section22title3}</h2>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
