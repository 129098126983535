import React from 'react';
import './styles.css';
import Section1 from './section1';
import Section2 from './section2';
import Section3 from './section3';
import Section4 from './section4';
import Section6 from './section6';
import PrivacyPolicySection from './privacy-policy';
import FooterSection1 from '../footer/footer-section1';

function Features() {
  return (
    <div className="features">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <PrivacyPolicySection />
      <Section6 />
      <FooterSection1 />
    </div>
  );
}

export default Features;
