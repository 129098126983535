import React from 'react'
import {
  Col,
  Row,
} from 'antd'
import {
  sSection1Title,
  sSection1SubTitle,
  sBills
} from '../../../utils/staticString';
import pinkBrushStroke from '../../../assets/images/pinkBrushStroke.svg'
import './styles.css'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1200, min: 992 },
    items: 3
  },
  smallTablet: {
    breakpoint: { max: 992, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 576 },
    items: 2
  }
};

function Section1() {
  return (
    <div className="section1">
      <Row justify="center" align="middle">
        <Col span={20} className="title">
          <img className="pinkBrushStroke" src={pinkBrushStroke} alt="pink-brush" />
          <span className="text"> {sSection1Title} </span>
          <p className="subTitle">{sSection1SubTitle}</p>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col 
          xs={20}
          sm={20}
          md={20}
          lg={18}
          xl={16}
          xxl={16}
        >
          <Carousel
            responsive={responsive}
            swipeable={true}
            arrows={false}
            draggable={true}
            // showDots={true}
            infinite={true}
            centerMode={false}
            autoPlay={true}
            autoPlaySpeed={1000}
            // keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={1000}
          >
            {
              sBills.map(bill => (
                <div
                  key={bill.name}
                  className="service"
                >
                  <Row justify="center" >
                    <img className="billImage" src={bill.image} alt={bill.name} />
                  </Row>
                  <Row justify="center" className="billTitle" >
                    {bill.name}
                  </Row>
                </div>
              ))
            }
          </Carousel>
          {/* </Row> */}
        </Col>
      </Row>
    </div>
  )
}

export default Section1
