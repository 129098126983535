import { intercomLink } from '../constants';
import axios from 'axios';
import { globalConstants, normalizeUrl } from '../constants';
import { Modal } from 'antd';

export const redirectToHelpCenter = () => {
  window.location.href = intercomLink;
};

const baseURL = normalizeUrl(globalConstants.API_BASE_URL, '/user-referral');

export const httprequest = async (body) => {
  return axios({
    method: 'post',
    url: baseURL,
    data: body,
  })
    .then((response) => {
      return { success: true , payload: response};
    })
    .catch((error) => {
      return { success: false, payload: error.response ? error.response: {}};
    });
};
