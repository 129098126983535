import { startsWith } from 'lodash';

export function normalizeUrl(baseUrl, url) {
  if (startsWith(url, baseUrl)) return url;
  return baseUrl.replace(/\/*$/, '') + '/' + url.replace(/^\/*/, '');
}

export const globalConstants = {
  // API BASE URL
  API_BASE_URL: normalizeUrl(
    process.env.AYLA_BASE_URL || 'http://localhost:8081/',
    '/api/'
  ),
};

// Links Added for Web Page

const intercomLink = 'https://intercom.help/aylaapp/en';
const facebookLink = 'https://www.facebook.com/meetayla';
const linkedinLink = 'https://www.linkedin.com/company/meetayla';
const twitterLink = 'https://twitter.com/meetaylaapp';
const instagramLink = 'https://www.instagram.com/meetaylaapp/';
const IosAppDownloadLink = 'https://apps.apple.com/au/app/noobill/id1476342608';
const AndoidAppDownloadLink =
  'https://play.google.com/store/apps/details?id=com.noobill';

export {
  intercomLink,
  facebookLink,
  instagramLink,
  linkedinLink,
  twitterLink,
  IosAppDownloadLink,
  AndoidAppDownloadLink,
};
