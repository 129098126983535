import React, { useState, useEffect } from 'react';
import './styles.css';
import australiaFlag from '../../../../assets/images/australia.png';
import { Row, Col, Button } from 'antd';

const PhoneInput = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    props.setContactNumber(phoneNumber);
  }, [phoneNumber]);

  const validateNumber = (e) => {
    // const pattern = /^[0-9]$/;
    if (!isNaN(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };

  return (
    <>
      <Row align="middle" className="phoneInputRow">
        <Col span={24}>
          <Row justify="center" align="middle">
            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <Row align="middle">
                <Col span={22}>
                  <img
                    src={australiaFlag}
                    alt="Australia"
                    className="australiaFlag"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} xxl={1}>
              <Row align="middle" justify="center">
                <Col span={22}>
                  <p className="plus61">+61</p>
                </Col>
              </Row>
            </Col>
            <Col xs={19} sm={19} md={11} lg={12} xl={12} xxl={12}>
              <Row align="middle" justify="center">
                <Col offset={3} span={21}>
                  <input
                    className="phoneInput"
                    onChange={validateNumber}
                    placeholder="Enter Number"
                    minLength={9}
                    maxLength={10}
                    value={phoneNumber}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={0} sm={0} md={9} lg={8} xl={8} xxl={8}>
              <Row align="middle" justify='end'>
                <button onClick={props.onSubmit} className="getStarted">
                  Get Started
                </button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="get-started" justify="center" align="center">
        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
          <Row align="bottom" justify='center'>
            <button onClick={props.onSubmit} className="getStarted">
              Get Started
            </button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PhoneInput;
