import React, {useEffect} from 'react';
import { Row, Col } from 'antd';
import './style.css';
import { privacyPolicyData } from '../../../utils/staticString';

export default function PrivacyPolicyPage() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacySection">
      <Row justify="center" gutter={[16, 32]}>
        <Col xs={22} sm={22} md={22} lg={20} xl={20} xxl={20}>
          <Row>
            <h2 className="headingtext">{privacyPolicyData.heading1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.heading2()}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section1title}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section1heading()}</h2>
          </Row>
          <Row className="listItems">
            <Col xs={22} sm={22} md={22} lg={22} xl={20} xxl={20}>
              <ul>
                {privacyPolicyData.section1list.map((ele, index) => (
                  <li className="titletext" key={index}>{ele}</li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row gutter={[0, 40]}>
            <Col span={24}>
              {/* {privacyPolicyData.section2heading.map((ele, index) => (
                <Row>
                  <Col xs={23} sm={23} md={23} lg={23} xl={5} xxl={3}>
                    <h2 className="headertexts">{ele.heading}:</h2>
                  </Col>
                  <Col xs={23} sm={23} md={23} lg={23} xl={19} xxl={21}>
                    <h2 className="titletext aligntext">{ele.text}</h2>
                  </Col>
                </Row>
              ))} */}
              <h2 className="titletext">{privacyPolicyData.section2heading()}</h2>
            </Col>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section2title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section3heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section3title1}</h2>
          </Row>
          <Row justify="center">
            <Col span={24}>
              {privacyPolicyData.section3title2.map((ele, index) => (
                <Row>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
                    <p>
                      <span className="headertexts">{ele.heading} :</span>
                      <span className="titletext aligntext">{ele.text}</span>
                    </p>
                  </Col>
                </Row>
              ))}
              {/* <Row className="listItems" justify="start">
                <Col span={23}>
                  <ul>
                    <li>
                      <h2 className="titletext">
                        {privacyPolicyData.section3title3}
                      </h2>
                    </li>
                    <li>
                      <h2 className="titletext">
                        {privacyPolicyData.section3title4}
                      </h2>
                    </li>
                  </ul>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section4heading}</h2>
          </Row>
          <Row gutter={[0, 40]}>
            <Col span={23}>
              {privacyPolicyData.section4title1.map((ele, index) => (
                <Row>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
                    <p>
                      <span className="headertexts">{ele.heading}:</span>
                      <span className="titletext aligntext">{ele.text}</span>
                    </p>                    
                  </Col>
                </Row>
              ))}
              <Row className="listItems" justify="start">
                <Col span={22}>
                  <Row>
                    <ul>
                      {privacyPolicyData.section4title2.map((ele, index) => (
                        <li className="titletext" key={index}>{ele}</li>
                      ))}
                    </ul>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section5heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section5title1}</h2>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="listItems" justify="center">
                <ul>
                  {privacyPolicyData.section5title2.map((ele, index) => (
                    <li className="titletext" key={index}>{ele}</li>
                  ))}
                </ul>
              </Row>
            </Col>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section6heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section6title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section6title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section7heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title2()}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title3}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title4}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title5}</h2>
          </Row>
          <Row>
            <Col span={23}>
              <h2 className="titletext">{privacyPolicyData.section7title6}</h2>
              <Row>
                <Col span={23}>
                  <Row className="listItems" justify="start">
                    <ul>
                      {privacyPolicyData.section7title7.map((ele, index) => (
                        <li className="titletext" key={index}>{ele}</li>
                      ))}
                    </ul>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title8}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section7title9}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section8heading}</h2>
          </Row>
          <Row gutter={[0, 40]}>
            <Col span={24}>
              {privacyPolicyData.section8title.map((ele, index) => (
                <Row>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
                    <p>
                      <span className="headertexts">{ele.heading}:</span>
                      <span className="titletext aligntext">{ele.text}</span>
                    </p>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row>
            <h2 className="headertexts">{privacyPolicyData.section9heading}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section9title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section9title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">
              {privacyPolicyData.section10heading}
            </h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section10title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section10title2}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">
              {privacyPolicyData.section11heading}
            </h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section11title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">
              {privacyPolicyData.section12heading}
            </h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section12title}</h2>
          </Row>
          <Row>
            <h2 className="headertexts">
              {privacyPolicyData.section14heading}
            </h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section14title1}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section14title2}</h2>
          </Row>
          <Row>
            <h2 className="titletext">{privacyPolicyData.section14title3}</h2>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
