import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'antd';
import './style.css';
import { successModalData, failureModalData } from '../utils/staticString';

const SuccessModal = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (props.modalData.success !== undefined) {
      setModalVisible(true);
    }
  }, [props.modalData]);
  
  return (
    <Row justify="center">
      <Col span={24}>
        <Modal
          className="modalAlignRow"
          // title="Success"
          centered
          visible={modalVisible}
          footer=""
          onCancel={() => setModalVisible(false)}
        >
          {
            props.modalData
            && props.modalData.success
            ? (
              <Row>
                <Col span={24}>
                  <Row className="justifyTextCenter">
                    <Col>
                      <img src={successModalData.bgImg} alt="" />
                    </Col>
                  </Row>
                  <Row className="justifyTextCenter">
                    <Col>
                      {/* <p>{props.modalData.message}</p> */}
                      <h2 className="heading">{successModalData.headingText}</h2>
                    </Col>
                  </Row>
                  <Row className="subtexts">
                    <Col>
                      {/* <p>{props.modalData.message}</p> */}
                      <h2>{successModalData.subText}</h2>
                      {/* <h2>{successModalData.subText2}</h2>
                      <h2>{successModalData.subText3}</h2> */}
                    </Col>
                  </Row>
                  <Row className="justifyTextCenter">
                    <Col>
                      <Button
                        className="successBtn"
                        onClick={() => setModalVisible(false)}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <Row className="justifyTextCenter">
                    <Col>
                      <img style={{transform: 'rotate(-180deg'}} src={successModalData.bgImg} alt="" />
                      
                    </Col>
                  </Row>
                  <Row className="justifyTextCenter">
                    <Col>
                      {/* <p>{props.modalData.message}</p> */}
                      <h2 className="heading">{failureModalData.headingText}</h2>
                    </Col>
                  </Row>
                  <Row className="subtexts">
                    <Col>
                      {/* <p>{props.modalData.message}</p> */}
                      <h2>{failureModalData.subText}</h2>
                    </Col>
                  </Row>
                  <Row className="justifyTextCenter">
                    <Col>
                      <Button
                        className="successBtn"
                        onClick={() => setModalVisible(false)}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
        </Modal>
      </Col>
    </Row>
  );
};

export { SuccessModal };
